import React from "react"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import CategoryItems from "../../components/CategoryItems"
import CollectionTemplate from "../../templates/CollectionTemplate"

const DecolletePage = () => {
  return (
    <CollectionTemplate
      collectionTitle="Decollete"
      classExtra="pendantscollectionpage"
    >
      <SEO
        title="Decollete"
        keywords={[
          `exclusive`,
          `australian`,
          `decollete`,
          `engagement`,
          `wedding`,
        ]}
        description={`Custom pendants - bridal, gifts or anniversaries. Find 'something blue' here! Vintage inspired designs. Gold and ethical, conflict free gemstones (diamond, moissanite, sapphire, morganite)`}
      />

      <div className="SEOContainer">
        {/* <div className="PicContain">
          <div className="SEOPendants" />
        </div> */}
        <div className="SEOText">
          We've got your perfect statement piece here. Our statement pendants
          draw inspiration from the beauty of vintage designs and are balanced
          beautifully with our delicate layering chain necklaces. Delicately
          designed and meticulously handcrafted, these pieces will turn heads
          and make you feel irresistible. All Rowe Collection designs are fully
          customisable, from gemstones to gold colour and purity. Want something
          completely unique? Contact us to begin your bespoke journey!
        </div>
      </div>
      <div className="productnavbar">
        <Link className="linkspacer" to="/collection">
          all
        </Link>{" "}
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/rings">
          rings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/earrings">
          earrings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/homme">
          homme
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bespoke">
          bespoke
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bracelets">
          bracelets
        </Link>
      </div>
      <div id="scroll" className="storewrap">
        <CategoryItems category="decollete" />
      </div>
    </CollectionTemplate>
  )
}

export default DecolletePage
